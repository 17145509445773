import {
  Box,
  Container,
  useColorModeValue,
  Heading,
  useBreakpointValue,
  Button,
  Text,
  Link,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";

const HomeCard = () => {
  const navigate = useNavigate();

  return (
    <Box as="section">
      <Container maxWidth="full">
        <Box
          px={{ base: "4", md: "8" }}
          py={{ base: "5", md: "8" }}
          bg="white"
          borderRadius="lg"
          boxShadow={useColorModeValue("md", "md-dark")}
        >
          <Box
            display="flex"
            flexDirection="column"
            justifyContent={useBreakpointValue(
              {
                base: "center",
                md: "space-between",
              },
              { ssr: false }
            )}
            alignItems={useBreakpointValue(
              {
                base: "center",
                md: "left",
              },
              { ssr: false }
            )}
            textAlign={useBreakpointValue(
              {
                base: "center",
                md: "left",
              },
              { ssr: false }
            )}
          >
            <Heading
              fontSize={useBreakpointValue(
                { base: "xl", md: "3xl" },
                { ssr: false }
              )}
            >
              Meet the Locker
            </Heading>
            <Box
              fontSize={useBreakpointValue(
                { base: "sm", md: "md" },
                { ssr: false }
              )}
              color="muted"
            >
              <Text marginTop={2}>
                The Locker is a Web3 application that allows you to protect and
                backup any of your mnemonic passphrases (or any other secret
                text) directly on the blockchain confidentialy and recover it
                whenever you need.
              </Text>
              <Text marginTop={2}>
                The idea is to able to recover your passphrases using a simple
                email. However, email alone is not very secured. So we added a
                social recovery mechanism that require you to have one or
                several people you trust to unlock your passphrases when you
                need to recover them.
              </Text>
              <Text marginTop={2}>
                To backup your mnemonic passphrase, you will need a single-use
                Keplr wallet, an email address and the wallet addresses of
                people you trust to hold the lock to your passphrase.
              </Text>
              <Text marginTop={2}>
                When you need top recover your passphrase, you will need yet
                another single-use Keplr wallet and ask your friends to transfer
                the lock back to your new wallet before recovering your
                passphrase with your email.
              </Text>
            </Box>
            <Box
              display="flex"
              mt={10}
              gap="10px"
              flexDirection={useBreakpointValue(
                { base: "column", lg: "row" },
                { ssr: false }
              )}
            >
              <Link style={{ textDecoration: "none" }}>
                <Button
                  size={useBreakpointValue(
                    { base: "sm", md: "md" },
                    { ssr: false }
                  )}
                  colorScheme={"teal"}
                  bg={"teal.400"}
                  rounded="3xl"
                  px={6}
                  _hover={{
                    bg: "teal.500",
                  }}
                  onClick={() => navigate("/backup")}
                >
                  Backup my Passphrase
                </Button>
              </Link>

              <Link
                href="https://prifi-labs.gitbook.io/the-locker/"
                isExternal
                style={{ textDecoration: "none" }}
              >
                <Button
                  size={useBreakpointValue(
                    { base: "sm", md: "md" },
                    { ssr: false }
                  )}
                  colorScheme={"teal"}
                  rounded="3xl"
                  px={6}
                  variant="outline"
                >
                  Documentation
                </Button>
              </Link>
              <Link
                href={
                  process.env.REACT_APP_MAIN_URL + "/The-Locker-whitepaper.pdf"
                }
                style={{ textDecoration: "none" }}
                target="_blank"
              >
                <Button
                  size={useBreakpointValue(
                    { base: "sm", md: "md" },
                    { ssr: false }
                  )}
                  colorScheme={"orange"}
                  variant="outline"
                  rounded="3xl"
                  px={6}
                >
                  Whitepaper
                </Button>
              </Link>
            </Box>
          </Box>
          <Box
            display="flex"
            justifyContent={useBreakpointValue(
              {
                base: "center",
                md: "flex-end",
              },
              { ssr: false }
            )}
            padding="2"
          >
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default HomeCard;
